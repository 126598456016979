<template>
  <type-template
    :acne-types="acneTypes"
    :concurrent-nodules="concurrentNodules"
    :has-nodules="hasNodules"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import TypeTemplate from '@/modules/questionnaire/components/steps/photo-analysis/pimples/type/TypeTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { ACNE_TYPE } from '@/modules/questionnaire/api/constants';

const fieldsToReset = ['concurrentNodules'];

const { requiredArrayField } = fieldBuilder;

const FIELDS = [...fieldsToReset.map(requiredArrayField), requiredArrayField('acneTypes')];

export default {
  name: 'Type',
  components: {
    TypeTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    hasNodules() {
      return this.acneTypes.includes(ACNE_TYPE.NODULES);
    }
  },
  watch: {
    acneTypes(newValue) {
      if (newValue && newValue.some(acne => acne === ACNE_TYPE.NODULES)) {
        this.scrollTo('#concurrent-nodules');
      } else {
        this.resetStoreFieldsByName(fieldsToReset);
      }
    },
    concurrentNodules() {
      this.showNextStep();
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'concurrentNodules') {
        return this.hasNodules;
      }

      return true;
    }
  }
};
</script>
